<template>
  <h1 v-if="isLoggedIn" class="title mr-[15px] mt-8 pb-6 md:mt-[45px] md:pb-[64px]">
    Hello, {{ userStore.user?.first_name }}!
  </h1>
  <div v-if="isLoggedIn" class="flex flex-col max-lg:justify-between md:flex-row">
    <div class="left_side-wrapper md:mr-[10%] lg:mr-[19.18%]">
      <button type="button" class="account__button active">
        Account Information <span class="account__button__disc"></span>
      </button>
      <router-link to="/account/ridehistory" class="account__button"
        >Ride History <span class="account__button__disc"></span
      ></router-link>
      <button @click="logOut()" type="button" class="account__button text-[#878787] max-md:hidden">
        Log Out
      </button>
    </div>
    <Form
      @submit="onSubmit"
      class="right_side-wrapper w-full"
      :validation-schema="accountInfoSchema"
      v-slot="{ errors, isSubmitting }"
    >
      <div
        class="right_side w-full max-w-none rounded-t-[40px] bg-white px-4 py-6 dark:bg-[#333639] max-md:mb-0 md:rounded-b-[40px] md:p-8 lg:max-w-[552px]"
      >
        <p class="text-background mb-5 dark:text-white md:hidden">Account Data</p>

        <span class="account_label">First Name</span>
        <Field
          type="text"
          name="first_name"
          maxlength="50"
          class="input account_input"
          :class="errors.first_name ? 'error' : ''"
          v-model="user.first_name"
          placeholder="First Name*"
          @beforeinput="utils.isLetter($event)"
          :disabled="!isEditable"
        />
        <span class="account_label">Last Name</span>
        <Field
          type="text"
          name="last_name"
          maxlength="50"
          class="input account_input"
          :class="errors.last_name ? 'error' : ''"
          v-model="user.last_name"
          placeholder="Last Name*"
          @beforeinput="utils.isLetter($event)"
          :disabled="!isEditable"
        />
        <span class="account_label">E-mail Address</span>
        <Field
          name="email"
          type="email"
          class="input account_input"
          placeholder="E-mail Address"
          v-model="user.email"
          :class="errors.email ? 'error' : ''"
          :disabled="!isEditable"
        />
        <span class="account_label">Mobile Phone Number</span>
        <vue-tel-input
          v-model="user.phone"
          :defaultCountry="user.country_prefix"
          @country-changed="countryChanged"
          v-on:keypress="utils.isNumber($event)"
          class="input account_input mb-8 px-4 py-0"
          :class="!isEditable ? 'disabled' : ''"
          :dropdownOptions="{
            showFlags: true,
            showDialCodeInList: true,
            showDialCodeInSelection: true
          }"
          v-bind="bindProps"
        ></vue-tel-input>
        <button
          type="button"
          v-if="!isEditable"
          class="summary_edit_button border-dark_main text-dark_main dark:text-main dark:border-main dark:hover:text-background w-full px-4 py-[18px] text-base/[17.6px] md:py-5 md:text-[18px]/[23.8px]"
          @click="openEdit()"
        >
          Edit
        </button>
        <button
          :disabled="isSubmitting"
          type="submit"
          v-if="isEditable"
          class="summary_edit_button save w-full px-4 py-[18px] text-base/[17.6px] md:py-5 md:text-[18px]/[23.8px]"
        >
          Save
        </button>
        <button @click="logOut()" type="button" class="mx-auto mt-6 text-[#878787] md:hidden">
          Log Out
        </button>
      </div>
    </Form>
  </div>
  <h1 v-if="!isLoggedIn" class="title mr-[15px] mt-[5rem]">
    Please login to view account information
  </h1>
  <router-link v-if="!isLoggedIn" to="/account/signin" class="button mt-[15px] w-fit"
    >Sign in</router-link
  >
  <swipe-modal
    v-model="isModal"
    v-if="isMobile"
    contents-height="60vh"
    border-top-radius="16px"
    background-color="ffffff"
    tip-color="#CDCFD0"
  >
    <car-popup-content />
  </swipe-modal>
</template>

<script setup>
import { ref, inject, reactive } from 'vue'
import swipeModal from '@takuma-ru/vue-swipe-modal'
import CarPopupContent from '@/components/CarPopupContent.vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { Form, Field } from 'vee-validate'
import { useMobile } from '@/compose/ismobile'
import { useUserStore } from '@/stores/user'
import { useRidesHistoryStore } from '@/stores/rides-history'
import { storeToRefs } from 'pinia'

const axios = inject('axios')
const router = useRouter()
const ridesStore = useRidesHistoryStore()
const userStore = useUserStore()
const { token, isLoggedIn, user } = storeToRefs(userStore)
const utils = inject('utils')
const isModal = ref(false)

const code = ref(null)
const country_prefix = ref(null)

const countryChanged = (country) => {
  code.value = '+' + country.dialCode
  country_prefix.value = country.iso2
}

const bindProps = reactive({
  mode: 'national',
  autoFormat: false,
  enabledFlags: true,
  preferredCountries: ['us', 'it', 'au', 'uk', 'in', 'ca', 'il', 'sg', 'ae'],
  inputClasses: 'input'
})

let isEditable = ref(false)
const { isMobile } = useMobile()

function logOut() {
  ridesStore.$reset()
  userStore.preventLogout()
  setTimeout(function () {
    router.push('/account/signin')
  }, 100)
}

const openEdit = () => {
  isEditable.value = true
}
const onSubmit = (values) => {
  let patch_user_id = user.value.id
  const data = {
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    phone: user.value.phone,
    user_id: user.value.id,
    code: code.value,
    country_prefix: country_prefix.value
  }

  const config = {
    headers: { Authorization: `Bearer ${token.value}` }
  }

  axios
    .patch('/users/' + patch_user_id, data, config)
    .then(function () {
      isEditable.value = false
    })
    .catch(function (error) {
      console.log(error)
    })
}
const accountInfoSchema = yup.object({
  email: yup.string().required().email().max(230),
  first_name: yup.string().required().max(50),
  last_name: yup.string().required().max(50)
})

onMounted(() => {})
</script>
