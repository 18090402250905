<template>
  <div
    v-if="isLoggedIn"
    class="mr-[15px] mt-8 flex flex-row flex-wrap items-end justify-between pb-6 md:mt-[45px] md:pb-[64px]"
  >
    <h1 class="title mr-[15px] mt-0">Your Rides</h1>
  </div>
  <div v-if="isLoggedIn" class="flex max-md:flex-col">
    <div class="left_side-wrapper md:mr-[19.18%]">
      <router-link to="/account/accountinformation" class="account__button"
        >Account Information <span class="account__button__disc"></span
      ></router-link>
      <button type="button" class="account__button active">
        Ride History <span class="account__button__disc"></span>
      </button>
      <button @click="logOut()" type="button" class="account__button text-[#878787]">
        Log Out
      </button>
    </div>
    <div class="fake-orders-wrapper relative w-full">
      <template v-if="!isRidesFetched">
        <div
          class="right_side-wrapper w-full"
          :class="{ ride: true, 'blur-sm': !isRidesFetched }"
          v-for="(fakeDataItem, index) in fakeData.slice(0, 4)"
          :key="index"
        >
          <div class="ride__top">
            <div class="ride__top__separator">
              <p class="ride_text">ID - {{ fakeDataItem.id }}</p>
              <p class="ride_text">Type of service - {{ fakeDataItem.typeOfService }}</p>
            </div>
            <div class="ride__top__separator">
              <p class="ride_text">Date - {{ fakeDataItem.date }}</p>
              <span class="ride_status waiting">Status - {{ fakeDataItem.status }}</span>
            </div>
          </div>
          <div class="ride__summary_button_wrapper">
            <div class="ride__summary_wrapper grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3">
              <div class="summary_item_wrapper ride_summary_item_wrapper">
                <span class="summary_item_title ride_summary_title"
                  >Pick Up Location: {{ fakeDataItem.pickupLocation }}</span
                >
              </div>
              <div class="summary_item_wrapper ride_summary_item_wrapper">
                <span class="summary_item_title ride_summary_title"
                  >Drop-Off Location: {{ fakeDataItem.dropOffLocation }}</span
                >
              </div>
              <div class="summary_item_wrapper ride_summary_item_wrapper">
                <span class="summary_item_title ride_summary_title"
                  >Duration: {{ fakeDataItem.duration }}</span
                >
              </div>
              <div class="summary_item_wrapper ride_summary_item_wrapper !mb-0 md:!mb-6">
                <span class="summary_item_title ride_summary_title"
                  >Date:{{ fakeDataItem.date }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Overlay -->
        <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center">
          <button @click="ridesHistoryStore.sendRequestBtn" class="button ride__button w-max">
            {{ buttonText }}
          </button>
        </div>
        <RideHistoryTimer
          v-if="isRequestPending"
          :totalSeconds="ridesHistoryStore.getSpendTime()"
          timer-css="account__button"
          title="Avg. time for importing rides histories"
        />
      </template>
      <template v-else>
        <div class="right_side-wrapper w-full space-y-6">
          <div
            v-for="(history, index) in rides"
            :class="{ cancelled: history.status === 4 }"
            :style="styleCondition(history)"
            :key="index"
            class="space-y-4 rounded-[30px] bg-white px-4 py-6 dark:bg-[#272729]"
          >
            <div class="flex items-center justify-between gap-x-5">
              <div class="flex w-full justify-between md:text-lg">
                <p class="lg:hidden">{{ history.type_of_service }}</p>
                <p class="max-lg:hidden">ID - {{ history.id }}</p>
                <p class="max-lg:hidden">
                  {{ history.created_at.replaceAll('T', ' ').split('.')[0] }}
                </p>
              </div>
              <span v-if="history.status === 1" class="ride_status waiting">Created</span>
              <span v-if="history.status === 2" class="ride_status pending">Pending</span>
              <span v-if="history.status === 3" class="ride_status waiting"
                >Waiting for Payment</span
              >
              <span v-if="history.status === 4" class="ride_status cancelled">Canceled</span>
              <span v-if="history.status === 5" class="ride_status done">Done</span>
            </div>
            <div
              class="grid gap-x-8 gap-y-4 border-y border-y-[#878787] py-4 *:space-y-2 dark:border-y-[#333639] sm:max-md:grid-cols-3 lg:grid-cols-3 lg:border-y-[#E8EDE8]/50 lg:py-6 dark:lg:border-y-[#3D4043] xl:grid-cols-4 xl:gap-x-16"
              :class="{ 'opacity-50': history.status === 4 }"
            >
              <div class="max-lg:hidden">
                <span class="ride_summary_title">Service type:</span>
                <input
                  type="text"
                  :value="history.type_of_service"
                  class="ride_summary_input"
                  placeholder="Service type:"
                  disabled
                />
              </div>

              <div v-if="history.pickup != null">
                <span class="ride_summary_title">Pick Up Location:</span>
                <input
                  type="text"
                  :value="history.pickup"
                  class="ride_summary_input"
                  placeholder="Pick Up Location:"
                  disabled
                />
              </div>
              <div v-if="history.dropoff != null">
                <span class="ride_summary_title">Drop-Off Location:</span>
                <input
                  type="text"
                  :value="history.dropoff"
                  class="ride_summary_input"
                  placeholder="Drop Off Location"
                  disabled
                />
              </div>
              <div v-if="history.hours != null">
                <span class="ride_summary_title">Duration:</span>
                <input
                  type="text"
                  :value="history.hours"
                  class="ride_summary_input"
                  placeholder="Duration"
                  disabled
                />
              </div>
              <div v-if="history.date_start != null">
                <span class="ride_summary_title">Date:</span>
                <input
                  type="text"
                  :value="history.date_start.split('.')[0].replaceAll('T', ' ')"
                  class="ride_summary_input"
                  placeholder="Date:"
                  disabled
                />
              </div>
            </div>
            <div class="space-y-1 lg:hidden">
              <!--
              v-if="
                history.consulting == null &&
                history.distance == null &&
                history.transport == null
              "
            -->
              <p>ID - {{ history.id }}</p>
              <p>{{ history.created_at.replaceAll('T', ' ').split('.')[0] }}</p>
            </div>
            <div
              class="flex flex-wrap gap-4 max-lg:flex-col lg:items-center lg:justify-end lg:text-lg/none"
            >
              <p
                v-if="history.total"
                class="max-lg:text-main text-2xl font-bold lg:text-lg lg:font-semibold"
              >
                {{ history.total }} EUR
              </p>
              <button
                class="bg-main text-background dark:bg-background dark:text-main dark:border-main w-full rounded-full px-4 py-4 font-semibold dark:border lg:w-max lg:py-3"
                v-if="history.pickup && history.dropoff"
                @click="formatHistoryData(history, 'return')"
              >
                Book Return Ride
              </button>
              <button
                class="border-dark_main text-dark_main w-full rounded-full border px-4 py-4 font-semibold dark:border-white dark:text-white lg:w-max lg:py-3"
                @click="formatHistoryData(history, 'duplicate')"
              >
                Duplicate Ride & Edit Time
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <h1 v-if="!isLoggedIn" class="title mr-[15px] mt-[5rem]">Please login to view ride history</h1>
  <router-link v-if="!isLoggedIn" to="/account/signin" class="button mt-[15px] w-fit"
    >Sign in
  </router-link>
  <swipe-modal
    class="md:hidden"
    v-model="isModal"
    contents-height="60vh"
    border-top-radius="16px"
    background-color="ffffff"
    tip-color="#CDCFD0"
    @close="closeModal()"
  >
    <history-popup-content
      :consulting="userObj.consulting"
      :reqs="userObj.reqs"
      :distance="userObj.distance"
      :transport="userObj.transport"
    />
  </swipe-modal>
</template>

<script setup>
import swipeModal from '@takuma-ru/vue-swipe-modal'
import HistoryPopupContent from '@/components/HistoryPopupContent.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { storeToRefs } from 'pinia'
//import { useCentrifugoStore } from '@/stores/centrifugo'
import { useUserStore } from '@/stores/user'
import { useOrderStore } from '@/stores/order'
import { useRidesHistoryStore } from '@/stores/rides-history'
import RideHistoryTimer from '@/components/RideHistoryTimer.vue'

const fakeData = ref([
  {
    id: 'XXXX1',
    typeOfService: 'XXXX1',
    date: 'XXXX1',
    status: 'XXXX1',
    pickupLocation: 'XXXX1',
    dropOffLocation: 'XXXX1',
    duration: 'XXXX1'
  },
  {
    id: 'XXXX2',
    typeOfService: 'XXXX2',
    date: 'XXXX2',
    status: 'XXXX2',
    pickupLocation: 'XXXX2',
    dropOffLocation: 'XXXX2',
    duration: 'XXXX2'
  },
  {
    id: 'XXXX3',
    typeOfService: 'XXXX3',
    date: 'XXXX3',
    status: 'XXXX3',
    pickupLocation: 'XXXX3',
    dropOffLocation: 'XXXX3',
    duration: 'XXXX3'
  }
])

const userStore = useUserStore()
const ridesHistoryStore = useRidesHistoryStore()
const orderStore = useOrderStore()

const {
  rides,
  isRidesFetched,
  isRequestSuccessful,
  isRequestPending,
  isRequestRefused,
  isNoRides,
  requestSent
} = storeToRefs(ridesHistoryStore)

let buttonText = computed(() => {
  if (isRequestSuccessful.value) return 'Succeed'
  if (isRequestPending.value) return 'Pending'
  if (isRequestRefused.value) return 'Refused'
  if (isNoRides.value && requestSent) return 'Rides histories empty'
  return 'Pending'
})

const { isLoggedIn } = storeToRefs(userStore)

const formatHistoryData = (history, type) => {
  orderStore.$reset()

  if (history.type_of_service === 'Tours / Roadshows') history.type_of_service = 'toursRoadshows'
  if (history.type_of_service === 'One Way Transfer') history.type_of_service = 'oneWayTransfer'
  if (history.type_of_service === 'Hourly as directed') history.type_of_service = 'hourlyAsDirected'

  orderStore.update({
    pickup: history.pickup,
    dropoff: history.dropoff,
    hours: history.hours,
    email: history.email,
    type_of_service: history.type_of_service,
    reqs: history.reqs,
    first_name: history.first_name,
    last_name: history.last_name,
    phone: history.phone,
    ride_history: true
  })

  if (type === 'return') returnRide(history)

  router.push({ name: 'home' })
}

const returnRide = (history) => {
  orderStore.update({
    dropoff: history.pickup,
    pickup: history.dropoff
  })
}

onBeforeMount(() => {
  ridesHistoryStore.loadRides()
  ridesHistoryStore.checkRequestSentOrNot()
})

function logOut() {
  ridesHistoryStore.$reset()
  userStore.preventLogout()
  setTimeout(function () {
    router.push('/account/signin')
  }, 100)
}

let isModal = ref(false)
const userObj = ref({
  consulting: '',
  reqs: '',
  distance: '',
  transport: ''
})

const router = useRouter()

const styleCondition = (order) => {
  if (
    (!checkTime(order, 'payment') && order.status === 3) ||
    (!checkTime(order, 'lead') && order.status === 2 && order.transport === null)
  )
    return { opacity: 0.5 }
  else return {}
}

const checkTime = (order, type) => {
  const now = moment()
  const orderCreated = moment(order.created_at)
  const diffInMinutes = now.diff(orderCreated, 'minutes')

  if (type === 'payment' && order.status === 3) {
    return diffInMinutes > 30
  }

  if (type === 'lead' && order.status === 2 && order.transport === null) {
    return diffInMinutes > 10
  }

  return false
}

const closeModal = () => {
  isModal.value = false
  document.querySelector('html').style.touchAction = 'auto'
  document.body.style.touchAction = 'auto'
  document.querySelector('html').style.overflowY = 'auto'
  document.body.style.overflowY = 'auto'
  document.querySelector('html').style.overscrollBehavior = 'auto'
  document.body.style.overscrollBehavior = 'auto'
}
</script>
